<template>
	<div
		class="bg-[#4F46E5] w-full rounded-lg shadow-2xl flex mx-auto justify-center gap-5 px-2 items-center bottom-nav">
		<routerLink to="/admin">
			<BottomNavigationItem active="admin">
				<svg data-v-cf1ec82f="" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
					aria-hidden="true" role="img" class="icon" width="30px" height="30px" viewBox="0 0 24 24" style="color: white">
					<path fill="currentColor"
						d="M5.25 4A3.25 3.25 0 0 0 2 7.25v9.5A3.25 3.25 0 0 0 5.25 20h13.5A3.25 3.25 0 0 0 22 16.75v-9.5A3.25 3.25 0 0 0 18.75 4zM5 7.75A.75.75 0 0 1 5.75 7h5.5a.75.75 0 0 1 0 1.5h-5.5A.75.75 0 0 1 5 7.75M6 13h7a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1m-.25-3.5h10.5a.75.75 0 0 1 0 1.5H5.75a.75.75 0 0 1 0-1.5">
					</path>
				</svg>
			</BottomNavigationItem>
		</routerLink>
		<a :href="`/${user?.name}?preview=true`">
			<BottomNavigationItem active="admin-preview">
				<icon name="material-symbols:preview" size="30" style="color: white" />
			</BottomNavigationItem>
		</a>
	</div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useUserStore } from "~/stores/user";
const { user } = storeToRefs(useUserStore());
onMounted(async () => {
	await useUserStore().getUser();
});
</script>

<style scoped>
@media (max-width: 400px) {
	.bottom-nav {
		gap: 0.3rem;
	}
}
</style>
